import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Box, Button, Grid, InputLabel, IconButton } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import FormikControl from '../../../Components/Formik/FormikControl';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import 'react-day-picker/dist/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionBoard: {
    backgroundColor: '#FFF',
    margin: 'auto',
    padding: '10px',
    boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
    borderRadius: '5px',
  },
  sectionHidden: {
    display: 'none',
  },
  sectionValidErrMsg: {
    color: 'red',
  },
}));

function FormikContainer(props) {
  let { rowData, vehicleList, adList, codename, insStatus } = props;
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const handleClickOpen = () => {
    setOpen(true); //開啟dialog
  };
  const handleClose = () => {
    setOpen(false); //關閉dialog
  };
  const [yearMon, setYearMon] = React.useState();
  const handleDateChange = (date) => {
    setYearMon(date);
  };

  //選單項目
  const selectVehicle = [{ key: '請選擇...', value: 0 }];
  const selectDay = [{ key: '請選擇...', value: 0 }];
  //初始值
  const initialValues = {
    timetableId: '',
    vehicle: 0,
    yearMon: new Date(),
    yearMonEdit: moment().format('YYYY-MM'),
    day1: '',
    day2: '',
    day3: '',
    day4: '',
    day5: '',
    day6: '',
    day7: '',
    day8: '',
    day9: '',
    day10: '',
    day11: '',
    day12: '',
    day13: '',
    day14: '',
    day15: '',
    day16: '',
    day17: '',
    day18: '',
    day19: '',
    day20: '',
    day21: '',
    day22: '',
    day23: '',
    day24: '',
    day25: '',
    day26: '',
    day27: '',
    day28: '',
    day29: '',
    day30: '',
    day31: '',
  };
  if (vehicleList) { //所有車輛
    vehicleList.forEach(item => {
      selectVehicle.push({ key: `${item.no} (${item.vehicleType.name})`, value: item.id });
    });
  }
  if (adList) { //所有店家的廣告
    adList.forEach(item => {
      selectDay.push({ key: `${item.id} | ${item.store.storeName}:${item.store.storeCode} | ${item.name}`, value: item.id });
    });
  }
  if (codename) { //所有車輛狀態
    codename.codeVehicleStatus.forEach(item => {
      selectDay.push({ key: `${item.code} | ${item.name}`, value: item.code });
    });
  }
  //設定初始資料
  if (rowData) {
    //第2次render時才有資料
    initialValues.timetableId = rowData.id;
    initialValues.vehicle = (rowData.vehicle && rowData.vehicle.id) ? rowData.vehicle.id : 0;
    initialValues.yearMon = new Date(rowData.year, rowData.month-1, 1);
    initialValues.yearMonEdit = rowData.year + '-' + rowData.month;
    initialValues.day1 = (rowData.day1) ? rowData.day1.code : '';
    initialValues.day2 = (rowData.day2) ? rowData.day2.code : '';
    initialValues.day3 = (rowData.day3) ? rowData.day3.code : '';
    initialValues.day4 = (rowData.day4) ? rowData.day4.code : '';
    initialValues.day5 = (rowData.day5) ? rowData.day5.code : '';
    initialValues.day6 = (rowData.day6) ? rowData.day6.code : '';
    initialValues.day7 = (rowData.day7) ? rowData.day7.code : '';
    initialValues.day8 = (rowData.day8) ? rowData.day8.code : '';
    initialValues.day9 = (rowData.day9) ? rowData.day9.code : '';
    initialValues.day10 = (rowData.day10) ? rowData.day10.code : '';
    initialValues.day11 = (rowData.day11) ? rowData.day11.code : '';
    initialValues.day12 = (rowData.day12) ? rowData.day12.code : '';
    initialValues.day13 = (rowData.day13) ? rowData.day13.code : '';
    initialValues.day14 = (rowData.day14) ? rowData.day14.code : '';
    initialValues.day15 = (rowData.day15) ? rowData.day15.code : '';
    initialValues.day16 = (rowData.day16) ? rowData.day16.code : '';
    initialValues.day17 = (rowData.day17) ? rowData.day17.code : '';
    initialValues.day18 = (rowData.day18) ? rowData.day18.code : '';
    initialValues.day19 = (rowData.day19) ? rowData.day19.code : '';
    initialValues.day20 = (rowData.day20) ? rowData.day20.code : '';
    initialValues.day21 = (rowData.day21) ? rowData.day21.code : '';
    initialValues.day22 = (rowData.day22) ? rowData.day22.code : '';
    initialValues.day23 = (rowData.day23) ? rowData.day23.code : '';
    initialValues.day24 = (rowData.day24) ? rowData.day24.code : '';
    initialValues.day25 = (rowData.day25) ? rowData.day25.code : '';
    initialValues.day26 = (rowData.day26) ? rowData.day26.code : '';
    initialValues.day27 = (rowData.day27) ? rowData.day27.code : '';
    initialValues.day28 = (rowData.day28) ? rowData.day28.code : '';
    initialValues.day29 = (rowData.day29) ? rowData.day29.code : '';
    initialValues.day30 = (rowData.day30) ? rowData.day30.code : '';
    initialValues.day31 = (rowData.day31) ? rowData.day31.code : '';
  }
  // 送出前檢查欄位
  const validationSchema = Yup.object({
    yearMon: Yup.string().required('**必填'),
  });
  //console.log('initialValues:', initialValues);
  // 新增 or 更新
  const onSubmit = async formData => {
    // 過程中判斷insStatus 新增或更新
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    let timetableId = formData.timetableId;
    let vehicleId = formData.vehicle;
    let timetableData = {
      day1: formData.day1,
      day2: formData.day2,
      day3: formData.day3,
      day4: formData.day4,
      day5: formData.day5,
      day6: formData.day6,
      day7: formData.day7,
      day8: formData.day8,
      day9: formData.day9,
      day10: formData.day10,
      day11: formData.day11,
      day12: formData.day12,
      day13: formData.day13,
      day14: formData.day14,
      day15: formData.day15,
      day16: formData.day16,
      day17: formData.day17,
      day18: formData.day18,
      day19: formData.day19,
      day20: formData.day20,
      day21: formData.day21,
      day22: formData.day22,
      day23: formData.day23,
      day24: formData.day24,
      day25: formData.day25,
      day26: formData.day26,
      day27: formData.day27,
      day28: formData.day28,
      day29: formData.day29,
      day30: formData.day30,
      day31: formData.day31,
    };
    if (insStatus) {
      //insStatus=true 新增
      timetableData.year = moment(yearMon).format('YYYY');
      timetableData.month = moment(yearMon).format('MM');

      await ApiService.Timetable.add(vehicleId, timetableData)
        .then(async res => {
          await ApiService.log.addEasy(storeCode, `/timetable/add`, token, res.status);
          setMessage(res.data.message);
        })
        .catch(err => {
          setMessage(err.response.data.message);
        });
      handleClickOpen();
    } else {
      //insStatus=false 更新
      timetableData.year = formData.yearMonEdit.substring(0, 4);
      timetableData.month = formData.yearMonEdit.substring(5, 7);

      await ApiService.Timetable.update(timetableId, vehicleId, timetableData)
        .then(async res => {
          await ApiService.log.addEasy(storeCode, `/timetable/update`, token, res.status);
          setMessage(res.data.message);
        })
        .catch(err => {
          setMessage(err.response.data.message);
        });
      handleClickOpen();
    }
  };
  
  return (
    <div>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {formik => {
          //console.log('formik', formik);
          let weekname = ['(日)','(一)','(二)','(三)','(四)','(五)','(六)'];
          let k = new Date(formik.values.yearMon.getFullYear(), formik.values.yearMon.getMonth(), 1).getDay();
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <FormikControl
                    control="hidden"
                    name="timetableId"
                    fullWidth
                    errors={formik.errors}
                    touched={formik.touched}
                  />
                  <InputLabel id="lbVehicle">車輛*</InputLabel>
                  <ErrorMessage name="vehicle" component="div" className={classes.sectionValidErrMsg} />
                  <FormikControl
                    control="select"
                    name="vehicle"
                    options={selectVehicle}
                    fullWidth
                    errors={formik.errors}
                    touched={formik.touched}
                    disabled={!insStatus}
                  />
                </Grid>
                { insStatus ? 
                  <Grid item xs={3}>
                    <InputLabel id="lbYearMon">年月：</InputLabel>
                    <ErrorMessage name="yearMon" component="div" className={classes.sectionValidErrMsg} />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM"
                          id="yearMon"
                          name="yearMon"
                          value={yearMon}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                :
                  <Grid item xs={3}>
                    <Box className={classes.section}>
                      <InputLabel id="lbYearMonEdit">年月：</InputLabel>
                      <ErrorMessage name="yearMonEdit" component="div" className={classes.sectionValidErrMsg} />
                      <FormikControl control="input" name="yearMonEdit" fullWidth errors={formik.errors} touched={formik.touched} disabled={!insStatus} />
                    </Box>
                  </Grid>
                }
                <Grid item xs={3}>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                &nbsp;
              </Grid>
              {/* 一個月的日期START */}
              <Grid container spacing={3}>
              {[...Array(32).keys()].slice(1).map((i) => {
                let j = i + k; //修改使用== i:日, j:位置, k:本月1日是星期幾 ==
                if (insStatus) { //===== 新增 =====
                  if (i%7 != 0) { //第1~6位
                    return (
                      <Grid item xs={1}>
                        <Box className={classes.section}>
                          <InputLabel id={"lbDay"+i}>{i}</InputLabel>
                          <ErrorMessage component="div" name={"day"+i} className={classes.sectionValidErrMsg} />
                          {/* <FormikControl control="input" name={"day"+i} fullWidth={false} errors={formik.errors} touched={formik.touched} /> */}
                          <FormikControl
                            control="select"
                            name={"day"+i}
                            options={selectDay}
                            fullWidth
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        </Box>
                      </Grid>
                    );
                  } else { //第7位
                    return (<>
                      <Grid item xs={1}>
                        <Box className={classes.section}>
                          <InputLabel id={"lbDay"+i}>{i}</InputLabel>
                          <ErrorMessage component="div" name={"day"+i} className={classes.sectionValidErrMsg} />
                          {/* <FormikControl control="input" name={"day"+i} fullWidth={false} errors={formik.errors} touched={formik.touched} /> */}
                          <FormikControl
                            control="select"
                            name={"day"+i}
                            options={selectDay}
                            fullWidth
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={5}></Grid>
                    </>);
                  }
                } else { //===== 修改 =====
                  if (i == 1 && j == 7) { //1日在第7位
                    return (<>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={1}>
                        <Box className={classes.section}>
                          <InputLabel id={"lbDay"+i}>{i} {weekname[(j-1)%7]}</InputLabel>
                          <ErrorMessage component="div" name={"day"+i} className={classes.sectionValidErrMsg} />
                          {/* <FormikControl control="input" name={"day"+i} fullWidth={false} errors={formik.errors} touched={formik.touched} /> */}
                          <FormikControl
                            control="select"
                            name={"day"+i}
                            options={selectDay}
                            fullWidth
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={5}></Grid>
                    </>);
                  } else if (i == 1 && j != 7) { //1日在第1~6位
                    return (<>
                      <Grid item xs={k}></Grid>
                      <Grid item xs={1}>
                        <Box className={classes.section}>
                          <InputLabel id={"lbDay"+i}>{i} {weekname[(j-1)%7]}</InputLabel>
                          <ErrorMessage component="div" name={"day"+i} className={classes.sectionValidErrMsg} />
                          {/* <FormikControl control="input" name={"day"+i} fullWidth={false} errors={formik.errors} touched={formik.touched} /> */}
                          <FormikControl
                            control="select"
                            name={"day"+i}
                            options={selectDay}
                            fullWidth
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        </Box>
                      </Grid>
                    </>);
                  } else if (i != 1 && j%7 != 0) { //2~31日在第1~6位
                    return (<>
                      <Grid item xs={1}>
                        <Box className={classes.section}>
                          <InputLabel id={"lbDay"+i}>{i} {weekname[(j-1)%7]}</InputLabel>
                          <ErrorMessage component="div" name={"day"+i} className={classes.sectionValidErrMsg} />
                          {/* <FormikControl control="input" name={"day"+i} fullWidth={false} errors={formik.errors} touched={formik.touched} /> */}
                          <FormikControl
                            control="select"
                            name={"day"+i}
                            options={selectDay}
                            fullWidth
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        </Box>
                      </Grid>
                    </>);
                  } else if (i != 1 && j%7 == 0) { //2~31日在第7位
                    return (<>
                      <Grid item xs={1}>
                        <Box className={classes.section}>
                          <InputLabel id={"lbDay"+i}>{i} {weekname[(j-1)%7]}</InputLabel>
                          <ErrorMessage component="div" name={"day"+i} className={classes.sectionValidErrMsg} />
                          {/* <FormikControl control="input" name={"day"+i} fullWidth={false} errors={formik.errors} touched={formik.touched} /> */}
                          <FormikControl
                            control="select"
                            name={"day"+i}
                            options={selectDay}
                            fullWidth
                            errors={formik.errors}
                            touched={formik.touched}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={5}></Grid>
                    </>);
                  }
                }
              })}
              </Grid>
              {/* 一個月的日期END */}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box m={2}>
                    <Button variant="outlined" color="primary" type="submit">
                      {insStatus ? '確定新增' : '確定更新'}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={history.goBack}>
                      取消
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'[車輛排班更新]'}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormikContainer;
