import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

// chart.js 設定config
const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '分析曲線圖',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export default function DataChart({ effectGameChart }) {
  const classes = useStyles();

  const data = {
    labels: [],
    datasets: [
      {
        label: '人數',
        data: {},
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  if (effectGameChart) {
    data.labels = effectGameChart.labels;
    data.datasets[0].data = effectGameChart.peopleCount.data; //人數
  }

  return (
    <div>
      <Box className={classes.section} style={{ height: '400px', paddingBottom: '20px' }}>
        <Line data={data} width={600} height={400} options={options} />
      </Box>
    </div>
  );
}
