import React from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Box, Button, Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FormikControl from '../../../Components/Formik/FormikControl';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import 'react-day-picker/dist/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionBoard: {
    backgroundColor: '#FFF',
    margin: 'auto',
    padding: '10px',
    boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
    borderRadius: '5px',
  },
  sectionHidden: {
    display: 'none',
  },
  sectionValidErrMsg: {
    color: 'red',
  },
}));

function FormikContainer(props) {
  let { rowData, playlist, insStatus } = props;
  //readonly = readonly == 'true' ? true : false; //轉換成boolean
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const handleClickOpen = () => {
    setOpen(true); //開啟dialog
  };
  const handleClose = () => {
    setOpen(false); //關閉dialog
    //history.push(`/device/list`);
  };
  //選單項目
  const selectDefaultPlayId1 = [{ key: '請選擇...', value: 0 }];
  const selectDefaultPlayId2 = [{ key: '請選擇...', value: 0 }];
  //初始值
  const initialValues = {
    macAddr: '',
    name: '',
    defaultPlayId1: 0,
    defaultPlayId2: 0,
  };
  //console.log('rowData:', rowData);

  if (playlist) {
    //設定選單項目
    playlist.forEach(item => {
      selectDefaultPlayId1.push({ key: item.name, value: item.id });
      selectDefaultPlayId2.push({ key: item.name, value: item.id });
    });
    //設定初始資料
    if (rowData) {
      //第2次render時才有資料
      initialValues.macAddr = rowData.macAddr;
      initialValues.name = rowData.name;
      initialValues.defaultPlayId1 = rowData.defaultPlay1.id ? rowData.defaultPlay1.id : 0;
      initialValues.defaultPlayId2 = rowData.defaultPlay2.id ? rowData.defaultPlay2.id : 0;
    }
  }
  // 送出前檢查欄位
  const validationSchema = Yup.object({
    macAddr: Yup.string().required('**必填'),
  });

  // 新增 or 更新
  const onSubmit = async formData => {
    // 過程中判斷insStatus 新增或更新
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    let deviceData = {
      macAddr: formData.macAddr,
      name: formData.name,
      defaultPlayId1: formData.defaultPlayId1 != 0 ? formData.defaultPlayId1 : null,
      defaultPlayId2: formData.defaultPlayId2 != 0 ? formData.defaultPlayId2 : null,
    };

    if (insStatus) {
      //insStatus=true 新增
      await ApiService.Device.add(deviceData)
        .then(async res => {
          await ApiService.log.addEasy(storeCode, `/device/add`, token, res.status);
          setMessage(res.data.message);
        })
        .catch(err => {
          setMessage(err.response.data.message);
        });
      handleClickOpen();
    } else {
      //insStatus=false 更新
      await ApiService.Device.update(deviceData)
        .then(async res => {
          await ApiService.log.addEasy(storeCode, `/device/update`, token, res.status);
          setMessage(res.data.message);
        })
        .catch(err => {
          setMessage(err.response.data.message);
        });
      handleClickOpen();
    }
  };

  return (
    <div>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {formik => {
          //console.log('formik', formik);
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box className={classes.section}>
                    <InputLabel id="lbMacAddr">設備MAC*</InputLabel>
                    <ErrorMessage name="macAddr" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="input"
                      name="macAddr"
                      fullWidth
                      errors={formik.errors}
                      touched={formik.touched}
                    />
                  </Box>
                  <Box className={classes.section}>
                    <InputLabel id="lbName">設備名稱</InputLabel>
                    <ErrorMessage name="name" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl control="input" name="name" fullWidth errors={formik.errors} touched={formik.touched} />
                  </Box>
                  <Box className={classes.section}>
                    <InputLabel id="lbDefaultPlayId1">預設播放清單1</InputLabel>
                    <ErrorMessage name="defaultPlayId1" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="defaultPlayId1"
                      options={selectDefaultPlayId1}
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={true}
                    />
                  </Box>
                  <Box className={classes.section}>
                    <InputLabel id="lbDefaultPlayId2">預設播放清單2</InputLabel>
                    <ErrorMessage name="defaultPlayId2" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="defaultPlayId2"
                      options={selectDefaultPlayId1}
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={true}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box m={2}>
                    <Button variant="outlined" color="primary" type="submit">
                      {insStatus ? '確定新增' : '確定更新'}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={history.goBack}>
                      取消
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'[設備更新]'}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormikContainer;
