import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SamplePage from './Pages/SamplePage';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Dashboard from './Dashboard';
import SimpleTable from './Table/SimpleTable';
import Dashboards from './Dashboards';
import MapSimple from './Maps/SimpleMap';
import MapKml from './Maps/MapKmLayer';
import Maps from './Maps';

// import Signin from './Pages/Auth/Signin';
import StoreAccountDataTable from './Pages/StoreAccount/StoreAccountDataTable';
import StoreAccountCreate from './Pages/StoreAccount/StoreAccountCreate';

import EditTable from './Table/EditTable';
import StoreList from './Pages/Store/StoreList';
import StoreCreate from './Pages/Store/StoreCreate';
import StoreInfo from './Pages/Store/StoreInfo';
import StoreAdminCreate from './Pages/Store/StoreAdminCreate';

import PlayList from './Pages/PlayList';
import AdList from './Pages/Ad/AdList';
import AdEdit from './Pages/Ad/AdEdit';
import AdRecord from './Pages/AdRecord/AdRecordList';
import AdRecordRoute from './Pages/AdRecord/AdRecordRoute';
import VehicleList from './Pages/Vehicle/VehicleList';
import VehicleEdit from './Pages/Vehicle/VehicleEdit';
import VehicleRouteList from './Pages/VehicleRoute/VehicleRouteList';
import VehicleRouteEdit from './Pages/VehicleRoute/VehicleRouteEdit';
import TimeTableList from './Pages/TimeTable/TimeTableList';
import TimeTableEdit from './Pages/TimeTable/TimeTableEdit';
import DeviceList from './Pages/Device/DeviceList';
import DeviceEdit from './Pages/Device/DeviceEdit';
import EffectAd from './Pages/EffectAd/EffectAdList';
import EffectAdDetail from './Pages/EffectAd/EffectAdDetail';
import EffectGame from './Pages/EffectGame/EffectGameList';
import EffectGameDetail from './Pages/EffectGame/EffectGameDetail';
import AdCheckList from './Pages/AdCheck/AdCheckList';
import AdCheckEdit from './Pages/AdCheck/AdCheckEdit';
import AdGameList from './Pages/AdGame/AdGameList';
// import ProductDataTable from './Pages/Product/ProductDataTable';
// import ProductCreate from './Pages/Product/ProductCreate';
// import CategoryDataTable from './Pages/Category/CategoryDataTable';
// import ActivityDataTable from './Pages/Activity/ActivityDataTable';
// import BannerDataTable from './Pages/Banner/BannerDataTable';
// import CouponDataTable from './Pages/Coupon/CouponDataTable';
// import CouponUsageDataTable from './Pages/CouponUsage/CouponUsageDataTable';
// import PointCardDataTable from './Pages/PointCard/PointCardDataTable';

import OrderDataTable from './Pages/Order/OrderDataTable';

// import MessageDataTable from './Pages/Message/MessageDataTable';
// import MessageCreate from './Pages/Message/MessageCreate';
// import ChartFrontendDataTable from './Pages/Chart/ChartFrontendDataTable';
// import ChartARGameDataTable from './Pages/Chart/ChartARGameDataTable';
import ChartCMSDataTable from './Pages/Chart/ChartCMSDataTable';
// import MemberDataTable from './Pages/Member/MemberDataTable';
import MemberDataDownload from './Pages/Member/MemberDataDownload';

import FirstPage from './Pages/FirstPage';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  //console.log('[routes/index.js] authUser', authUser);

  if (location.pathname === '' || location.pathname === '/') {
    if (authUser) {
      return <Redirect to={'/first-page'} />;
    } else {
      return <Redirect to={'/signin'} />;
    }
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/first-page'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/dashboard" component={Dashboards} />
        <RestrictedRoute path="/sample-page" component={SamplePage} />
        <RestrictedRoute path="/first-page" component={FirstPage} />

        {/* 認證授權 客製*/}
        {/* <RestrictedRoute path="/login" component={Signin} comment="登入頁面" />*/}
        {/* 認證授權 原廠*/}
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute path="/dashboard2" component={Dashboard} />
        <RestrictedRoute path="/simple-table" component={SimpleTable} />
        <RestrictedRoute path="/edit-table" component={EditTable} />

        {/* 店家資料 */}
        <RestrictedRoute path="/store/list" component={StoreList} comment="店家資料列表" />
        <RestrictedRoute path="/store/create" component={StoreCreate} comment="新增店家" />
        <RestrictedRoute path="/store/edit" component={StoreCreate} comment="異動店家" />
        <RestrictedRoute path="/store/info" component={StoreInfo} comment="店家資訊" />
        <RestrictedRoute path="/store/admin/create" component={StoreAdminCreate} comment="新增店家管理者" />

        {/* 帳號資料 */}
        <RestrictedRoute path="/store/account/list" component={StoreAccountDataTable} comment="帳號資料列表" />
        <RestrictedRoute path="/store/account/create" component={StoreAccountCreate} comment="新增帳號" />
        {/* 播放清單管理 */}
        <RestrictedRoute path="/playlist/list" component={PlayList} comment="播放清單管理" />
        {/* 廣告管理 */}
        <RestrictedRoute path="/ad/list" component={AdList} comment="廣告管理" />
        <RestrictedRoute path="/ad/edit" component={AdEdit} comment="廣告編輯" />
        {/* 廣告送件管理 */}
        <RestrictedRoute path="/adcheck/list" component={AdCheckList} comment="廣告送件查核" />
        <RestrictedRoute path="/adcheck/edit" component={AdCheckEdit} comment="查核送件內容" />
        {/* 執行記錄管理 */}
        <RestrictedRoute path="/adrecord/list" component={AdRecord} comment="執行記錄管理" />
        {/* 執行記錄管理內容 */}
        <RestrictedRoute path="/adrecord/route" component={AdRecordRoute} comment="執行記錄管理內容" />
        {/* 車輛管理 */}
        <RestrictedRoute path="/vehicle/list" component={VehicleList} comment="車輛管理" />
        <RestrictedRoute path="/vehicle/edit" component={VehicleEdit} comment="車輛編輯" />
        {/* 車輛排班 */}
        <RestrictedRoute path="/timetable/list" component={TimeTableList} comment="車輛排班管理" />
        <RestrictedRoute path="/timetable/edit" component={TimeTableEdit} comment="車輛排班編輯" />
        {/* 路線管理 */}
        <RestrictedRoute path="/route/list" component={VehicleRouteList} comment="路線管理" />
        <RestrictedRoute path="/route/edit" component={VehicleRouteEdit} comment="路線編輯" />
        {/* 設備管理 */}
        <RestrictedRoute path="/device/list" component={DeviceList} comment="設備管理" />
        <RestrictedRoute path="/device/edit" component={DeviceEdit} comment="設備編輯" />
        {/* 訂單資料 */}
        <RestrictedRoute path="/order/list" component={OrderDataTable} comment="訂單記錄列表" />
        {/* 廣告成效分析 */}
        <RestrictedRoute path="/effect-ad/list" component={EffectAd} comment="廣告成效分析" />
        <RestrictedRoute path="/effect-ad/detail" component={EffectAdDetail} comment="廣告成效分析內容" />
        {/* 遊戲成效分析 */}
        <RestrictedRoute path="/effect-game/list" component={EffectGame} comment="遊戲成效分析" />
        <RestrictedRoute path="/effect-game/detail" component={EffectGameDetail} comment="廣告成效分析內容" />
        {/* 遊戲啟動 */}
        <RestrictedRoute path="/adgame/list" component={AdGameList} comment="遊戲啟動" />

        <Route path="/visualization/map/simple" component={MapSimple} />
        <Route path="/map/kml" component={MapKml} />
        <Route path="/maps" component={Maps} />

        {/* 產品資料 */}
        {/* <RestrictedRoute path="/product/list" component={ProductDataTable} comment="產品資料列表" />
        <RestrictedRoute path="/product/create" component={ProductCreate} comment="新增產品" /> */}
        {/* 分類資料 */}
        {/* <RestrictedRoute path="/category/list" component={CategoryDataTable} comment="分類資料列表" /> */}
        {/* 最新活動 */}
        {/* <RestrictedRoute path="/activity/banner/zero/list" component={ActivityDataTable} comment="最新活動列表" /> */}
        {/* Banner資料 */}
        {/* <RestrictedRoute path="/activity/banner/one/list" component={BannerDataTable} comment="Banner資料列表" /> */}
        {/* 優惠券資料 */}
        {/* <RestrictedRoute path="/coupon/list" component={CouponDataTable} comment="優惠券資料列表" />
        <RestrictedRoute path="/coupon/usage/list" component={CouponUsageDataTable} comment="優惠券使用記錄資料列表" /> */}
        {/* 集點卡資料 */}
        {/* <RestrictedRoute path="/pointcard/list" component={PointCardDataTable} comment="集點卡資料列表" /> */}
        {/* 訊息資料 */}
        {/* <RestrictedRoute path="/message/list" component={MessageDataTable} comment="訊息管理列表" />
        <RestrictedRoute path="/message/add" component={MessageCreate} comment="新增訊息" /> */}
        {/* 報表資料 */}
        {/* <RestrictedRoute path="/chart/frontend" component={ChartFrontendDataTable} comment="使用者報表" />
        <RestrictedRoute path="/chart/argame" component={ChartARGameDataTable} comment="AR遊戲報表" /> */}
        <RestrictedRoute path="/chart/cms" component={ChartCMSDataTable} comment="後台報表" />
        {/* 會員資料 */}
        {/* <RestrictedRoute path="/member/list" component={MemberDataTable} comment="會員資料列表" /> */}
        <RestrictedRoute path="/member/download" component={MemberDataDownload} comment="資料下載" />

        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
