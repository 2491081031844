import React from 'react';
import { Field } from 'formik';
import InputField from '@material-ui/core/Input';

function Input(props) {
  const { label, name, errors, touched, disabledControl, ...rest } = props;
  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Field
        as={InputField}
        id={name}
        disabled={disabledControl}
        name={name}
        error={touched[name] && Boolean(errors[name])}
        helpertext={touched[name] && errors[name]}
        {...rest}
      />
    </div>
  );
}

export default Input;
