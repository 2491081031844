import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import { Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
// table 欄位標題設定
const columns = {
  parentsColoumns: [
    { title: 'No.', render: rowData => rowData.tableData.id + 1 },
    { title: '車號', field: 'no' },
    {
      title: '播放設備',
      field: 'deviceOne.macAddr',
      render: rowData => `${rowData.deviceOne.macAddr} ${rowData.deviceOne.name}`,
    },
    {
      title: '感測設備',
      field: 'sensorOne.macAddr',
      render: rowData => `${rowData.sensorOne.macAddr} ${rowData.sensorOne.name}`,
    },
    { title: '車輛類型', field: 'vehicleType.name', editable: 'never' },
    { title: '車輛狀態', field: 'vehicleStatus.name' },
    //{ title: '更新時間', field: 'updatedAt', type: 'date', editable: 'never' },
  ],
};

export default function DataTable({ vehicleList, getVehicleList }) {
  const history = useHistory();

  // 刪除Vehicle API
  const removeVehicle = async rowData => {
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.Vehicle.delete(rowData.id).then(async res => {
      await ApiService.log.addEasy(storeCode, `/vehicle/delete`, token, res.status);
    });
    getVehicleList();
  };

  // 編輯按鈕
  const actions = [
    {
      icon: () => (
        <Button variant="outlined" color="primary">
          新增車輛
        </Button>
      ),
      isFreeAction: true,
      tooltip: '新增車輛',
      onClick: (event, rowData) => {
        history.push(`/vehicle/edit?id=`);
      },
    },
    rowData => ({
      icon: () => <TABLE_ICONS.Edit />,
      tooltip: '編輯車輛',
      onClick: () => {
        history.push(`/vehicle/edit?id=${rowData.id}`);
      },
    }),
  ];

  return (
    <MaterialTable
      title=""
      icons={TABLE_ICONS}
      columns={columns.parentsColoumns}
      data={vehicleList}
      options={{
        actionsColumnIndex: -1,
      }}
      localization={{
        body: {
          editRow: {
            deleteText: <IntlMessages id="material.table.delete.text" />,
          },
        },
      }}
      actions={actions}
      editable={{
        onRowDelete: rowData => removeVehicle(rowData),
      }}
    />
  );
}
