import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import DataTable from './DataTable';
import FormDialog from './FormDialog';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.store.account.list'} />, isActive: true },
];

export default function Table() {
  const classes = useStyles();
  return (
    <PageContainer heading={<IntlMessages id="pages.store.account.list" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section}>
        <DataTable />
      </Box>
    </PageContainer>
  );
}
