import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import Field from 'routes/Common/Field';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { createStore } from '../../../../redux/actions/StoreAction';
import { createStoreFirstAdmin, updateStoreFirstAdmin } from 'redux/actions/StoreAdminAction';
import qs from 'qs';
import commonService from '../../../../services/CommonService';
import storeAdminService from '../../../../services/StoreAdminService';
// import DataTable from './DataTable';
// import FormDialog from './FormDialog';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.store.list'} />, link: '/store/list' },
  { label: <IntlMessages id={'pages.store.create'} />, isActive: true },
];

const validate = values => {
  const errors = {};
  if (!values.adminAccount) {
    errors.adminAccount = '必填';
  } else if (values.adminAccount.length > 50) {
    errors.adminAccount = '超過最大字數長度(50)';
  }
  if (!values.adminAccount) {
    errors.adminAccount = '必填';
  } else if (values.adminAccount.length > 50) {
    errors.adminAccount = '超過最大字數長度(50)';
  }
  if (!values.adminName) {
    errors.adminName = '必填';
  } else if (values.adminName.length > 50) {
    errors.adminName = '超過最大字數長度(50)';
  }
  if (!values.adminEmail) {
    errors.adminEmail = '必填';
  } else if (values.adminEmail.length > 50) {
    errors.adminEmail = '超過最大字數長度(50)';
  }
  // if (!values.adminPassword) {
  //   errors.adminPassword = '必填';
  // } else if (values.adminPassword.length < 8) {
  //   errors.adminPassword = '密碼至少包含八個字符(50)';
  // } else if (values.adminPassword.length > 50) {
  //   errors.adminPassword = '超過最大字數長度(50)';
  // }
  // if (!values.confirmPassword) {
  //   errors.confirmPassword = '必填';
  // } else if (values.confirmPassword.length < 8) {
  //   errors.confirmPassword = '再次輸入密碼至少包含八個字符(50)';
  // } else if (values.confirmPassword.length > 50) {
  //   errors.confirmPassword = '再次輸入密碼超過最大字數長度(50)';
  // } else if (values.adminPassword !== values.confirmPassword) {
  //   errors.confirmPassword = '請輸入相同密碼';
  // }

  return errors;
};

export default function Table() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const qstr = location.search;
  const qstrobj = qs.parse(qstr, { ignoreQueryPrefix: true });
  const storeId = qstrobj.i;
  const storeFirstAdminId = qstrobj.sai;
  let isEditMode = storeFirstAdminId !== 'undefined' ? true : false;
  //console.log('qstrobj', qstrobj, 'isEditMode', isEditMode);

  useEffect(() => {
    // dispatch(retrieveStore(storeId));
    if (storeFirstAdminId) {
      storeAdminService
        .findStoreFirstAdmin(storeId)
        .then(res => {
          // console.log('findStoreFirstAdmin res', res);
          if (res.status === 200) {
            //console.log('res.data', res.data);
            formik.setFieldValue('storeId', storeId);
            formik.setFieldValue('storeFirstAdminId', storeFirstAdminId /*res.data.adminAccountId*/);
            formik.setFieldValue('adminAccount', res.data.adminAccount);
            formik.setFieldValue('adminName', res.data.adminName);
            formik.setFieldValue('adminEmail', res.data.adminEmail);
            formik.setFieldValue('sendEmailResetPwd', isEditMode ? '0' : '1');
          }
        })
        .catch(() => {
          // setSubmitting(false);
          // alertService.error(error);
        });
    }
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      storeId: '',
      storeFirstAdminId: '',
      adminAccount: '',
      adminName: '',
      adminEmail: '',
      sendEmailResetPwd: '1',
    },
    validate,
    onSubmit: values => {
      const storeData = {
        adminAccount: values.adminAccount,
        adminName: values.adminName,
        adminEmail: values.adminEmail,
        storeId: values.storeId,
        storeFirstAdminId: values.storeFirstAdminId,
        sendEmailResetPwd: values.sendEmailResetPwd,
      };
      commonService.debugMsg(JSON.stringify(storeData, null, 2));
      if (isEditMode) {
        //console.log('storeData',storeData);
        dispatch(updateStoreFirstAdmin(storeData));
      } else {
        dispatch(createStoreFirstAdmin(storeData));
      }
      history.push(`/store/list`);
    },
  });

  return (
    <PageContainer heading={<IntlMessages id="pages.store.first.account.create" />} breadcrumbs={breadcrumbs}>
      <Box
        className={classes.section}
        sx={{
          backgroundColor: '#FFF',
          margin: 'auto',
          padding: '10px',
          boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
          borderRadius: '5px',
        }}>
        <form onSubmit={formik.handleSubmit}>
          <div className="container">
            <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <input
                  className="form-control"
                  id="storeId"
                  name="storeId"
                  type="hidden"
                  value={formik.values.storeId}
                  readOnly
                />
                <input
                  className="form-control"
                  id="storeFirstAdminId"
                  name="storeFirstAdminId"
                  type="hidden"
                  value={formik.values.storeFirstAdminId}
                  readOnly
                />
              </div>
            </div>
            <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <label>{qstrobj.n}</label>
              </div>
            </div>
            <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <input
                  className="form-control"
                  id="adminAccount"
                  name="adminAccount"
                  type="text"
                  placeholder="請輸入店管帳號(必填)"
                  onChange={formik.handleChange}
                  value={formik.values.adminAccount}
                />
                <div className="help-block text-danger">
                  {formik.errors.adminAccount ? <div>{formik.errors.adminAccount}</div> : null}
                </div>
              </div>
            </div>
            <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <input
                  className="form-control"
                  id="adminName"
                  name="adminName"
                  type="text"
                  placeholder="請輸入店管名稱(必填)"
                  onChange={formik.handleChange}
                  value={formik.values.adminName}
                />
                <div className="help-block text-danger">
                  {formik.errors.adminName ? <div>{formik.errors.adminName}</div> : null}
                </div>
              </div>
            </div>
            <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <input
                  className="form-control"
                  id="adminEmail"
                  name="adminEmail"
                  type="email"
                  placeholder="請輸入店管郵件(必填)"
                  onChange={formik.handleChange}
                  value={formik.values.adminEmail}
                />
              </div>
              <div className="help-block text-danger">
                {formik.errors.adminEmail ? <div>{formik.errors.adminEmail}</div> : null}
              </div>
            </div>
            <div className="row align-items-stretch mb-2">
              <div className="col-md-12">
                <input
                  className="form-check-input"
                  id="sendEmailResetPwd"
                  name="sendEmailResetPwd"
                  type="radio"
                  placeholder=""
                  onChange={formik.handleChange}
                  value="1"
                  checked={formik.values.sendEmailResetPwd === '1'}
                  disabled={isEditMode ? '' : 'disabled'}
                />
                <label className="mr-3 ml-1" htmlFor="sendEmailResetPwd">
                  系統自動產生(重設)密碼並發送EMAIL
                </label>
                <input
                  className="form-check-input"
                  id="notResetPwd"
                  name="sendEmailResetPwd"
                  type="radio"
                  placeholder=""
                  onChange={formik.handleChange}
                  value="0"
                  checked={formik.values.sendEmailResetPwd === '0'}
                  disabled={isEditMode ? '' : 'disabled'}
                />
                <label className="ml-1" htmlFor="notResetPwd">
                  不產生(重設)密碼
                </label>
              </div>
            </div>

            <div className="row align-items-stretch mb-2 mt-3">
              <div className="col-md-12">
                <button type="submit" className="btn btn-primary mr-2">
                  {isEditMode ? (
                    <IntlMessages id="button.store.first.account.update" />
                  ) : (
                    <IntlMessages id="button.store.first.account.create" />
                  )}
                </button>

                <button type="button" className="btn btn-outline-secondary" onClick={history.goBack}>
                  {<IntlMessages id="button.store.first.account.cancel" />}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Box>
    </PageContainer>
  );
}
