import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import { Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Parser from 'react-html-parser';

const useStyles = makeStyles(theme => ({
  button: {
    '& > *': {
      margin: theme.spacing(4),
    },
  },
}));

// table 欄位標題設定
const columns = {
  parentsColoumns: [
    { title: 'No.', render: rowData => rowData.tableData.id + 1 },
    {
      title: 'ID | 名稱 | 類型',
      width: '90%',
      render: rowData => `${rowData.id} | ${rowData.name} | ${rowData.moveType.name}`,
    },
    /*{ title: '類型', width: '40%', field: 'moveType.name' },
    { title: '日期', width: '18%', field: 'broadcastDate' },
    { title: '車種', width: '10%', field: 'vehicle.type.name' },
    { title: '路線', width: '10%', field: 'vehicle.route.name' },
    { title: '模式', width: '10%', field: 'mode.name' },
    { title: '狀態', width: '10%', field: 'payment.statusName' },*/
    /*{ title: '播放清單', field: 'playlistOne.name' },*/
    /*{ title: '更新時間', field: 'updatedAt', type: 'date' },*/
  ],
};

export default function DataTable({ ad, getAd }) {
  //const dispatch = useDispatch();
  //const stores = useSelector(state => state.storeReducer);
  //const [data, setData] = useState(ad.adBasic);
  const storeId = localStorageService.getStoreId();
  const storeCode = localStorageService.getStoreCode();
  const token = localStorageService.getToken();
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState();
  const handleClickOpen = rowData => {
    setRowData(rowData);
    setOpen(true); //開啟dialog
  };
  const handleClose = () => {
    setOpen(false); //關閉dialog
  };
  const handleGameAppStart = async () => {
    if (rowData.unitedDeviceId && rowData.unitedDeviceId.length > 0) {
      const adId = rowData.id;
      const gameId = rowData.game.id;
      const deviceId = rowData.unitedDeviceId.split(',')[0];
      await ApiService.Playbox.query({ deviceId: deviceId }).then(async res => {
        let roomId = res.data.ad.storeGame.unitedRoomId;
        //console.log('roomId', roomId);
        await ApiService.Playbox.gameappstartwithkey(adId, gameId, roomId, 'adcarstartkey').then(async res => {
          await ApiService.log.addEasy(storeCode, `/game/app/start/${adId}/${gameId}/${roomId}/key`, token, res.status);
          //console.log('message', res.data);
          let message =
            Parser(res.data)[0].props.children[0].props.children[0].props.content +
            ' --- ' +
            Parser(res.data)[0].props.children[0].props.children[1].props.content;
          //console.log('parser', message);
          alert(message);
        });
      });
    }
  };
  const handleGameAppStop = async event => {
    if (rowData.unitedDeviceId && rowData.unitedDeviceId.length > 0) {
      const adId = rowData.id;
      const gameId = rowData.game.id;
      const deviceId = rowData.unitedDeviceId.split(',')[0]; //送第1個device即可
      await ApiService.Playbox.gameappstop(adId, gameId, deviceId).then(async res => {
        await ApiService.log.addEasy(storeCode, `/game/app/stop/${adId}/${gameId}/${deviceId}`, token, res.status);
        //console.log('message', res.data.message);
        alert(res.data.message);
      });
    }
  };
  const handleAdEdit = async () => {
    //setOpen(false); //關閉dialog
    history.push(`/ad/edit?id=${rowData.id}&readonly=0`);
    //window.location.href = strUrl;
  };

  /*const handlePayStatus = () => {
    updatePayStatus();
    //setOpen(false); //關閉dialog
    history.go(0);
  };
  const updatePayStatus = async () => {
    await ApiService.Ad.updateCheck(rowData.id, 'request').then(async res => {
      await ApiService.log.addEasy(storeCode, `/ad/check/update`, token, res.status);
      //setMessage(res.data.message);
    });
  };*/
  /*const handleCheckOrder = () => {
    checkOrderAPI(token);
    //setOpen(false); //關閉dialog
  };
  var PaymentModel = {
    paymentId: '',
    uuid: '',
    storeCode: '',
    storeName: '',
    mbrCode: '',
    mbrName: '',
    mbrMobile: '',
    mbrGender: '',
    mbrBirth: '',
    mbrEmail: '',
    mbrAddr: '',
    payMemo: '',
    prodTotlePrice: 0,
    productList: [],
    returnUrl: '',
  };*/

  // const checkOrderAPI = accessToken => {
  //   PaymentModel.paymentId = '123'; //response.data['paymentId'];
  //   PaymentModel.uuid = '';
  //   PaymentModel.storeCode = rowData.store.storeCode;
  //   PaymentModel.storeName = rowData.store.storeName;
  //   PaymentModel.mbrCode = '';
  //   PaymentModel.mbrName = '';
  //   PaymentModel.mbrMobile = '';
  //   PaymentModel.mbrGender = '';
  //   PaymentModel.mbrBirth = '';
  //   PaymentModel.mbrEmail = '';
  //   PaymentModel.mbrAddr = '';
  //   PaymentModel.payMemo = '';
  //   PaymentModel.prodTotlePrice = 8000;
  //   PaymentModel.productList = null; //orderClass.productList;
  //   PaymentModel.returnUrl = ''; //pathname;
  //sendPaymentToBrowser();

  /*
    // checkOrder: handle form and api
    ApiService.Order.checkoutOrder(_appConfig.storeCode, accessToken, Number(customPrice), orderClass)
      .then((response => {
        holog('CustomPrice', "checkOrder response: " + response);
        holog('CustomPrice', "checkOrder response.status: " + response.status);

        if (response.status === 200) {
          holog('CustomPrice', "checkOrder response: 200 == " + JSON.stringify(response.data));
          PaymentModel.paymentId = response.data['paymentId'];
          PaymentModel.uuid = 'LIFFAPP-SIGNATURE';
          PaymentModel.storeCode = _appConfig.storeCode;
          PaymentModel.storeName = MemberModel.storeName;
          PaymentModel.mbrCode = MemberModel.mbrCode;
          PaymentModel.mbrName = MemberModel.mbrNick;
          PaymentModel.mbrMobile = MemberModel.mbrMobile;
          PaymentModel.mbrGender = '';
          PaymentModel.mbrBirth = '';
          PaymentModel.mbrEmail = '';
          PaymentModel.mbrAddr = '';
          PaymentModel.payMemo = '';
          PaymentModel.prodTotlePrice = Number(customPrice);
          PaymentModel.productList = orderClass.productList;
          PaymentModel.returnUrl = pathname;

          sendPaymentToBrowser();
        }
      }))
      .catch(function (error) {
        if (error.response.status === 401 && props.lineProfile === null) {
          ApiServices.Auth.refreshToken(
            _appConfig.storeCode,
            tokenCookies.refreshToken,
            MemberModel.mbrMobile,
            tokenCookies.refreshToken, //'LIFFAPP-SIGNATURE',
            tokenCookies.refreshToken, //'LINE-USER-ID',
          ).then((response => {
            if (response.status === 200) {

              checkOrderAPI(response.data['accessToken']);

              if (response.data["accessToken"] !== undefined) setTokenCookies("accessToken", response.data['accessToken'], { path: '/' });
              if (response.data["refreshToken"] !== undefined) setTokenCookies("refreshToken", response.data['refreshToken'], { path: '/' });
            }
          }))
            .catch(function (error) {
              if (error.response.status === 401) {
                removeTokenCookies("accessToken");
              }
              if (error.response) {
                holog('refreshToken', 'error.status: ' + error.response.status);
                holog('refreshToken', 'error.data: ' + JSON.stringify(error.response.data));
                holog('refreshToken', 'error.headers: ' + JSON.stringify(error.response.headers));
              } else if (error.request) {
                holog('refreshToken', 'error.request: ' + JSON.stringify(error.request));
              } else {
                holog('refreshToken', 'error.message: ' + JSON.stringify(error.message));
              }
            });
        }
        // Line Login 註冊 token過期: refreshToken (line)
        ApiServices.Auth.lineRefreshToken(
          _appConfig.storeCode,
          tokenCookies.refreshToken,
          props.lineProfile.userId, 
          tokenCookies.refreshToken,
          tokenCookies.refreshToken,
          props.liff.getAccessToken(),
          props.liff.getIDToken()
        ).then(response => {
          checkOrderAPI(response.data['accessToken']);
          if (response.data["accessToken"] !== undefined) setTokenCookies("accessToken", response.data['accessToken'], { path: '/' });
          if (response.data["refreshToken"] !== undefined) setTokenCookies("refreshToken", response.data['refreshToken'], { path: '/' });
        })
        if (error.response) {
          holog('error', 'error.status: ' + error.response.status);
          holog('error', 'error.data: ' + JSON.stringify(error.response.data));
          holog('error', 'error.headers: ' + JSON.stringify(error.response.headers));
        } else if (error.request) {
          holog('error', 'error.request: ' + JSON.stringify(error.request));
        } else {
          holog('error', 'error.message: ' + JSON.stringify(error.message));
        }
      });*/
  // };

  /*const sendPaymentToBrowser = async () => {
    let strData = JSON.stringify(PaymentModel);
    var strToBase64 = encodeURIComponent(Buffer.from(strData).toString('base64'));
    var strUrl = 'https://pay.lciad.net/checkout/selection?data=' + strToBase64;
    console.log('CustomPrice', 'strData == ' + strData);
    console.log('CustomPrice', 'strToBase64encodeURI == ' + strToBase64);
    console.log('CustomPrice', 'strUrl == ' + strUrl);

    window.location.href = strUrl;
  };*/

  //useEffect(() => {}, [dispatch]);
  // 刪除Ad API
  /*const removeAd = async rowData => {
    //console.log('刪除廣告', rowData);
    await ApiService.Ad.delete(storeId, rowData.id).then(async res => {
      await ApiService.log.addEasy(storeCode, `/ad/delete/${storeCode}`, token, res.status);
    });
    getAd();
  };*/

  // 編輯按鈕
  const actions = [
    rowData => ({
      disabled: rowData.moveType.code != 'store_united_game',
      icon: () => (
        <Button variant="outlined" color="primary">
          {rowData.moveType.code != 'store_united_game' ? 'x' : '開啟'}
        </Button>
      ),
      tooltip: '開啟聯合遊戲',
      onClick: (event, rowData) => {
        handleClickOpen(rowData);
      },
    }),
    /*{
      icon: () => (
        <Button variant="outlined" color="primary">
          新增廣告
        </Button>
      ),
      isFreeAction: true,
      tooltip: '新增廣告',
      onClick: (event, rowData) => {
        history.push(`/ad/edit?id=`);
      },
    },
    rowData => ({
      //disabled: ['request'].includes(rowData.payment.statusCode), //申請中
      icon: () =>
        ['request'].includes(rowData.payment.statusCode) ? (
          <TABLE_ICONS.VisibilityIcon />
        ) : ['accept'].includes(rowData.payment.statusCode) ? (
          <TABLE_ICONS.BorderColorIcon />
        ) : (
          <TABLE_ICONS.Edit />
        ),
      tooltip: ['request', 'accept'].includes(rowData.payment.statusCode) ? '變更廣告' : '編輯廣告',
      onClick: () => {
        let readonly = ['request'].includes(rowData.payment.statusCode)
          ? 1
          : ['accept'].includes(rowData.payment.statusCode)
          ? 2
          : 0;
        history.push(`/ad/edit?id=${rowData.id}&readonly=${readonly}`);
      },
    }),
    rowData => ({
      disabled: ['request'].includes(rowData.payment.statusCode), //申請中
      icon: () => <TABLE_ICONS.ShoppingCartIcon />,
      tooltip: '送件與支付',
      onClick: (event, rowData) => {
        handleClickOpen(rowData);
      },
    }),*/
  ];

  return (
    <div>
      <MaterialTable
        title="搜尋"
        icons={TABLE_ICONS}
        columns={columns.parentsColoumns}
        data={ad}
        options={{
          actionsColumnIndex: -1,
        }}
        /*localization={{
          body: {
            editRow: {
              deleteText: <IntlMessages id="ad.table.delete.text" />,
            },
          },
        }}*/
        actions={actions}
        /*editable={{
          isDeletable: rowData => ['', 'unpay'].includes(rowData.payment.statusCode),
          onRowDelete: rowData => removeAd(rowData),
        }}*/
      />
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'[遊戲啟動與停止] - '}
          {rowData ? rowData.name : ''}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description" />
          <div className={classes.button} align="center">
            <Button
              variant="outlined"
              size="medium"
              onClick={handleAdEdit}
              autoFocus
              href="#outlined-buttons"
              /*disabled={rowData && ['', 'request'].includes(rowData.payment.statusCode)}*/
            >
              遊戲[{rowData && rowData.game.id ? rowData.game.id : '無'}]變更
            </Button>
            <div>設備ID: {rowData && rowData.unitedDeviceId ? rowData.unitedDeviceId : '無'}</div>
            <Button
              variant="outlined"
              size="large"
              onClick={handleGameAppStart}
              autoFocus
              href="#outlined-buttons"
              /*disabled={rowData && ['', 'request'].includes(rowData.payment.statusCode)}*/
            >
              遊戲開始啟動
            </Button>
            <div>---</div>
            <Button
              variant="outlined"
              size="large"
              onClick={handleGameAppStop}
              href="#outlined-buttons"
              /*disabled={rowData && ['paid', 'request', 'accept'].includes(rowData.payment.statusCode)}*/
            >
              遊戲強制停止
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
