import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import { Box, Button, Grid, InputLabel, CardMedia } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import { makeStyles } from '@material-ui/styles';
import FormikControl from '../../../Components/Formik/FormikControl';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import moment from 'moment';
import { zhTW } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MapDialog from '../../RouteMap/MapDialog';
import http from '../../../../http-common';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionBoard: {
    backgroundColor: '#FFF',
    margin: 'auto',
    padding: '10px',
    boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
    borderRadius: '5px',
  },
  sectionHidden: {
    display: 'none',
  },
  sectionValidErrMsg: {
    color: 'red',
  },
}));

function FormikContainer(props) {
  let { rowData, codename, insStatus, readonly } = props;
  readonly = parseInt(readonly); //0:全編輯, 1:全唯讀, 2:部分唯讀
  const history = useHistory();
  const classes = useStyles();
  //選擇日期
  const [days, setDays] = React.useState();
  /* const BusinessLogic = () => {
    const { values } = useFormikContext();
    useEffect(() => {
      console.log('values:',values);
      console.log('days:',days);
      const dayFormat = 'YYYY-MM-DD';
      let mdays = [];
      if (days && days.length > 0) {
        //當日曆選擇日期時
        mdays = days.sort((a, b) => a.valueOf() - b.valueOf());
      }
      console.log('mdays:',mdays);
      values.broadcastDate1 = mdays && mdays.length > 0 ? moment(mdays[0]).format(dayFormat) : '';
      values.broadcastDate2 = mdays && mdays.length > 1 ? moment(mdays[1]).format(dayFormat) : '';
      values.broadcastDate3 = mdays && mdays.length > 2 ? moment(mdays[2]).format(dayFormat) : '';
      values.broadcastDate4 = mdays && mdays.length > 3 ? moment(mdays[3]).format(dayFormat) : '';
      values.broadcastDate5 = mdays && mdays.length > 4 ? moment(mdays[4]).format(dayFormat) : '';
      values.broadcastDate6 = mdays && mdays.length > 5 ? moment(mdays[5]).format(dayFormat) : '';
      // initialValues.broadcastDate1 = mdays && mdays.length > 0 ? moment(mdays[0]).format(dayFormat) : '';
      // initialValues.broadcastDate2 = mdays && mdays.length > 1 ? moment(mdays[1]).format(dayFormat) : '';
      // initialValues.broadcastDate3 = mdays && mdays.length > 2 ? moment(mdays[2]).format(dayFormat) : '';
      // initialValues.broadcastDate4 = mdays && mdays.length > 3 ? moment(mdays[3]).format(dayFormat) : '';
      // initialValues.broadcastDate5 = mdays && mdays.length > 4 ? moment(mdays[4]).format(dayFormat) : '';
      // initialValues.broadcastDate6 = mdays && mdays.length > 5 ? moment(mdays[5]).format(dayFormat) : '';
    }, [values]);
    return null;
  }; */

  //上傳圖檔(BannerImage)
  const [bannerImage, setBannerImage] = React.useState();
  const [bannerWidth, setBannerWidth] = React.useState(0);
  const [bannerHeight, setBannerHeight] = React.useState(0);
  const [imgBannerImage, setImgBannerImage] = React.useState();
  const [newBannerImage, setNewBannerImage] = React.useState();
  const [newBannerWidth, setNewBannerWidth] = React.useState(0);
  const [newBannerHeight, setNewBannerHeight] = React.useState(0);
  const inputFileBannerImage = useRef(null);
  const inputFileBannerImageButton = () => {
    inputFileBannerImage.current.click();
  };
  const inputFileBannerImageOnChange = async event => {
    if (event.target.files[0] !== undefined) {
      const mediaFile = event.target.files[0];
      const fileURL = URL.createObjectURL(mediaFile);
      let resolution = new Promise(res => {
        //取得圖片 長x寬
        var img = new Image();
        img.onload = function() {
          setNewBannerWidth(this.width);
          setNewBannerHeight(this.height);
          res(this.width + '*' + this.height);
        };
        img.src = fileURL;
      });
      setNewBannerImage(fileURL);
      setImgBannerImage(mediaFile);
    }
  };
  const deleteBannerImageButton = () => {
    rowData.bannerImage = null;
    rowData.bannerWidth = 0;
    rowData.bannerHeight = 0;
    setBannerImage(null);
    setBannerWidth(0);
    setBannerHeight(0);
  };
  //上傳圖檔(unitedBanner)
  const [unitedBanner, setUnitedBanner] = React.useState();
  const [imgUnitedBanner, setImgUnitedBanner] = React.useState();
  const [newUnitedBanner, setNewUnitedBanner] = React.useState();
  const inputFileUnitedBanner = useRef(null);
  const inputFileUnitedBannerButton = () => {
    inputFileUnitedBanner.current.click();
  };
  const inputFileUnitedBannerOnChange = async event => {
    if (event.target.files[0] !== undefined) {
      const mediaFile = event.target.files[0];
      const fileURL = URL.createObjectURL(mediaFile);
      setNewUnitedBanner(fileURL);
      setImgUnitedBanner(mediaFile);
    }
  };
  const deleteUnitedBannerButton = () => {
    rowData.unitedBanner = null;
    setUnitedBanner(null);
  };

  //預設月份
  let mMonth = moment().toDate();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState();
  const handleClickOpen = () => {
    setOpen(true); //開啟dialog
  };
  const handleClose = () => {
    setOpen(false); //關閉dialog
    history.push(`/ad/list`);
  };
  const [startYearMon, setStartYearMon] = React.useState(null);
  const [stopYearMon, setStopYearMon] = React.useState(null);
  const handleStartDateChange = date => {
    setStartYearMon(date);
  };
  const handleStopDateChange = date => {
    setStopYearMon(date);
  };

  //選單項目
  const selectMoveType = [{ key: '請選擇...', value: 0 }];
  const selectVehicleType = [{ key: '請選擇...', value: 0 }];
  const selectVehicleRoute = [{ key: '請選擇...', value: 0 }];
  const selectPlayMode = [{ key: '請選擇...', value: 0 }];
  const selectPlayId1 = [{ key: '請選擇...', value: 0 }];
  const selectPlayId2 = [{ key: '請選擇...', value: 0 }];
  const selectGameId = [{ key: '請選擇...', value: 0 }];
  //初始值
  const initialValues = {
    id: 0,
    name: '',
    moveType: 'move_play',
    vehicleType: 'car',
    vehicleRoute: 0,
    broadcastDate1: '',
    broadcastDate2: '',
    broadcastDate3: '',
    broadcastDate4: '',
    broadcastDate5: '',
    broadcastDate6: '',
    mode: 'basic',
    playId1: 0,
    playId2: 0,
    fixLocation: '',
    gameId: 1,
    gameEndUri: '',
    gameWinUri: '',
    unitedRoomId: '',
    unitedDeviceId: '',
    unitedBanner: '',
    waitSeconds: 60,
    orientation: '',
    muxToken: '',
    streamUrl: '',
    enabled: false,
    startAt: null,
    stopAt: null,
    streamLogUrl: '',
    bannerImage: '',
    bannerWidth: '0',
    bannerHeight: '0',
    enabledQrcodeLog: false,
    enabledBanner: false,
  };
  //console.log('rowData:', rowData);
  //console.log('codename:', codename);
  if (codename) {
    //設定選單項目
    codename.codeMoveType.forEach(item => {
      selectMoveType.push({ key: item.name, value: item.code });
    });
    codename.codeVehicleType.forEach(item => {
      selectVehicleType.push({ key: item.name, value: item.code });
    });
    codename.routeList.forEach(item => {
      selectVehicleRoute.push({ key: item.name, value: item.id });
    });
    codename.codePlayMode.forEach(item => {
      selectPlayMode.push({ key: item.name, value: item.code });
    });
    codename.playlist.forEach(item => {
      selectPlayId1.push({ key: item.name, value: item.id });
    });
    codename.playlist.forEach(item => {
      selectPlayId2.push({ key: item.name, value: item.id });
    });
    codename.game.forEach(item => {
      selectGameId.push({ key: item.name, value: item.id });
    });
    //設定初始資料
    if (rowData) {
      //第2次render時才有資料
      initialValues.id = rowData.id;
      initialValues.name = rowData.name;
      initialValues.moveType = rowData.moveType.code;
      initialValues.vehicleType = rowData.vehicle.type.code;
      initialValues.vehicleRoute = rowData.vehicle.route.id;
      initialValues.mode = rowData.mode.code;
      initialValues.playId1 = rowData.playlistOne.id;
      initialValues.playId2 = rowData.playlistTwo.id;
      initialValues.fixLocation = rowData.fixLocation;
      initialValues.gameId = rowData.game.id;
      initialValues.gameEndUri = rowData.gameEndUri;
      initialValues.gameWinUri = rowData.gameWinUri;
      initialValues.unitedRoomId = rowData.unitedRoomId;
      initialValues.unitedDeviceId = rowData.unitedDeviceId;
      initialValues.unitedBanner = rowData.unitedBanner;
      initialValues.waitSeconds = rowData.waitSeconds;
      initialValues.orientation = rowData.orientation;
      initialValues.muxToken = rowData.muxToken;
      initialValues.streamUrl = rowData.streamUrl;
      initialValues.enabled = rowData.enabled;
      initialValues.startAt = rowData.startAt;
      initialValues.stopAt = rowData.stopAt;
      initialValues.streamLogUrl = rowData.streamLogUrl;
      initialValues.bannerImage = rowData.bannerImage;
      initialValues.bannerWidth = rowData.bannerWidth;
      initialValues.bannerHeight = rowData.bannerHeight;
      initialValues.enabledQrcodeLog = rowData.enabledQrcodeLog;
      initialValues.enabledBanner = rowData.enabledBanner;
      if (!startYearMon && rowData.startAt) setStartYearMon(rowData.startAt);
      if (!stopYearMon && rowData.stopAt) setStopYearMon(rowData.stopAt);
      //initialValues.broadcastDate = days && days.length > 0 ? days.map(d => format(d, 'yyyy/MM/dd')).join(',') : rowData.broadcastDate;
      if (!unitedBanner && rowData.unitedBanner) setUnitedBanner(rowData.unitedBanner);
      if (!bannerImage && rowData.bannerImage) setBannerImage(rowData.bannerImage);
      if (!bannerWidth && rowData.bannerWidth) setBannerWidth(rowData.bannerWidth);
      if (!bannerHeight && rowData.bannerHeight) setBannerHeight(rowData.bannerHeight);
    }
    const dayFormat = 'YYYY-MM-DD';
    let mdays = [];
    if (days && days.length > 0) {
      //當日曆選擇日期時
      mdays = days.sort((a, b) => a.valueOf() - b.valueOf());
    } else {
      //當讀取資料初始日期時
      if (rowData) {
        let arrayDays = [];
        rowData.broadcastDate.split(',').forEach(item => {
          arrayDays.push(moment(item, dayFormat));
        });
        mdays = arrayDays.sort((a, b) => a.valueOf() - b.valueOf());
      }
    }
    initialValues.broadcastDate1 = mdays && mdays.length > 0 ? moment(mdays[0]).format(dayFormat) : '';
    initialValues.broadcastDate2 = mdays && mdays.length > 1 ? moment(mdays[1]).format(dayFormat) : '';
    initialValues.broadcastDate3 = mdays && mdays.length > 2 ? moment(mdays[2]).format(dayFormat) : '';
    initialValues.broadcastDate4 = mdays && mdays.length > 3 ? moment(mdays[3]).format(dayFormat) : '';
    initialValues.broadcastDate5 = mdays && mdays.length > 4 ? moment(mdays[4]).format(dayFormat) : '';
    initialValues.broadcastDate6 = mdays && mdays.length > 5 ? moment(mdays[5]).format(dayFormat) : '';
    console.log('initialValues:', initialValues);
  }

  // 送出前檢查欄位
  const validationSchema = Yup.object({
    name: Yup.string().required('**必填'),
    moveType: Yup.string().min(2, '**必填'),
    vehicleType: Yup.string().min(2, '**必填'),
    mode: Yup.string().min(2, '**必填'),
    playId1: Yup.number().min(1, '**必填'),
    broadcastDate1: Yup.string().required('**必填'),
  });

  // 新增 or 更新
  const onSubmit = async formData => {
    //console.log('formData', formData);
    // 過程中判斷insStatus 新增或更新
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    let broadcastDate = [];
    if (formData.broadcastDate1) {
      broadcastDate.push(formData.broadcastDate1);
    }
    if (formData.broadcastDate2) {
      broadcastDate.push(formData.broadcastDate2);
    }
    if (formData.broadcastDate3) {
      broadcastDate.push(formData.broadcastDate3);
    }
    if (formData.broadcastDate4) {
      broadcastDate.push(formData.broadcastDate4);
    }
    if (formData.broadcastDate5) {
      broadcastDate.push(formData.broadcastDate5);
    }
    if (formData.broadcastDate6) {
      broadcastDate.push(formData.broadcastDate6);
    }
    let adData = {
      name: formData.name,
      moveTypeCode: formData.moveType,
      vehicleTypeCode: formData.vehicleType,
      vehicleRouteId: formData.vehicleRoute,
      playModeCode: formData.mode,
      playlistOneId: formData.playId1,
      playlistTwoId: formData.playId2,
      fixLocation: formData.fixLocation,
      gameId: formData.gameId,
      gameWinUri: formData.gameWinUri,
      gameEndUri: formData.gameEndUri,
      unitedRoomId: formData.unitedRoomId,
      unitedDeviceId: formData.unitedDeviceId,
      waitSeconds: formData.waitSeconds,
      orientation: formData.orientation,
      muxToken: formData.muxToken,
      streamUrl: formData.streamUrl,
      enabled: formData.enabled,
      startAt: startYearMon,
      stopAt: stopYearMon,
      streamLogUrl: formData.streamLogUrl,
      enabledQrcodeLog: formData.enabledQrcodeLog,
      enabledBanner: formData.enabledBanner,
      broadcastDate: broadcastDate.join(','),
      marquee: '',
      memo: '',
    };
    //有新圖片要上傳(bannerImage)
    if (imgBannerImage) {
      await ApiService.Image.upload(storeCode, 'ad', imgBannerImage).then(res => {
        adData.bannerImage = res.data.uuidName;
        adData.bannerWidth = newBannerWidth;
        adData.bannerHeight = newBannerHeight;
        ApiService.log.addEasy(storeCode, `/store/${storeCode}/upload/ad`, token, res.status);
      });
    } else if (!bannerImage) {
      adData.bannerImage = null;
      adData.bannerWidth = 0;
      adData.bannerHeight = 0;
    }
    //有新圖片要上傳(unitedBanner)
    if (imgUnitedBanner) {
      await ApiService.Image.upload(storeCode, 'ad', imgUnitedBanner).then(res => {
        adData.unitedBanner = res.data.uuidName;
        ApiService.log.addEasy(storeCode, `/store/${storeCode}/upload/ad`, token, res.status);
      });
    } else if (!unitedBanner) {
      adData.unitedBanner = null;
    }

    if (insStatus) {
      //insStatus=true 新增
      await ApiService.Ad.add(storeId, adData).then(async res => {
        await ApiService.log.addEasy(storeCode, `/ad/add/${storeCode}`, token, res.status);
        setMessage(res.data.message);
      });
      handleClickOpen();
    } else {
      //insStatus=false 更新
      await ApiService.Ad.update(storeId, rowData.id, adData).then(async res => {
        await ApiService.log.addEasy(storeCode, `/ad/update/${storeCode}`, token, res.status);
        setMessage(res.data.message);
      });
      handleClickOpen();
    }
  };

  return (
    <div>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {formik => {
          //console.log('formik', formik);
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box className={classes.section}>
                    {/*<BusinessLogic/>*/}
                    <InputLabel id="lbName">廣告名稱*</InputLabel>
                    <ErrorMessage name="name" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="input"
                      name="name"
                      fullWidth
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={[1].includes(readonly)}
                    />
                  </Box>
                </Grid>
                {/*左邊*/}
                <Grid item xs={6}>
                  <Box className={classes.section}>
                    <InputLabel id="lbMoveType">廣告類型*</InputLabel>
                    <ErrorMessage name="moveType" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="moveType"
                      options={selectMoveType}
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={[1, 2].includes(readonly)}
                    />
                  </Box>
                  <Box className={classes.section}>
                    <InputLabel id="lbVehicleType">車種/廣告機*</InputLabel>
                    <ErrorMessage name="vehicleType" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="vehicleType"
                      options={selectVehicleType}
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={[1, 2].includes(readonly)}
                    />
                  </Box>
                  {formik.values.moveType == 'move_play' ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbVehicleRoute">路線</InputLabel>
                      <FormikControl
                        control="select"
                        name="vehicleRoute"
                        options={selectVehicleRoute}
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                      <MapDialog routeId={formik.values.vehicleRoute} disabled={formik.values.vehicleRoute == 0} />
                    </Box>
                  ) : null}
                  {formik.values.moveType == 'fix_play' || formik.values.moveType == 'fix_game' ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbFixLocation">定點地址</InputLabel>
                      <FormikControl
                        control="input"
                        name="fixLocation"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  <Box className={classes.section}>
                    <InputLabel id="lbMode">播放模式*</InputLabel>
                    <ErrorMessage name="mode" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="mode"
                      options={selectPlayMode}
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={[1].includes(readonly)}
                    />
                  </Box>
                  <Box className={classes.section}>
                    <InputLabel id="lbPlayId1">播放清單(主)*</InputLabel>
                    <ErrorMessage name="playId1" component="div" className={classes.sectionValidErrMsg} />
                    <FormikControl
                      control="select"
                      name="playId1"
                      options={selectPlayId1}
                      errors={formik.errors}
                      touched={formik.touched}
                      disabled={[1].includes(readonly)}
                    />
                  </Box>
                  {formik.values.mode != 'basic' ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbPlayId2">播放清單(副)</InputLabel>
                      <FormikControl
                        control="select"
                        name="playId2"
                        options={selectPlayId2}
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {['fix_game', 'store_alone_game', 'store_united_game'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbGameId">遊戲種類</InputLabel>
                      <FormikControl
                        control="select"
                        name="gameId"
                        options={selectGameId}
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {['fix_game', 'store_alone_game', 'store_united_game'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbWaitSeconds">遊戲啟動等待加入秒數</InputLabel>
                      <FormikControl
                        control="input"
                        name="waitSeconds"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {['fix_game', 'store_alone_game', 'store_united_game'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbGameEndUri">請輸入遊戲結束連結網址(URI)</InputLabel>
                      <FormikControl
                        control="input"
                        name="gameEndUri"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {['fix_game', 'store_alone_game', 'store_united_game'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbGameWinUri">請輸入遊戲優勝連結網址(URI)</InputLabel>
                      <FormikControl
                        control="input"
                        name="gameWinUri"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {['store_united_game'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbUnitedRoomId">聯合遊戲RoomID開頭(自動加_timestamp)</InputLabel>
                      <FormikControl
                        control="input"
                        name="unitedRoomId"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {['store_united_game'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbUnitedDeviceId">聯合遊戲設備IDs(ID1,ID2,ID3...)</InputLabel>
                      <FormikControl
                        control="input"
                        name="unitedDeviceId"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {['move_play', 'fix_play','store_united_game','store_alone_game','store_streaming'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbEnabledBanner">[顯示]播廣告時的Banner</InputLabel>
                      <input
                        id="enabledBanner"
                        name="enabledBanner"
                        type="radio"
                        placeholder=""
                        onChange={formik.handleChange}
                        value="1"
                        checked={formik.values.enabledBanner == true}
                      />
                      <label class="MuiFormLabel-root">啟用　</label>
                      <input
                        id="enabledBanner"
                        name="enabledBanner"
                        type="radio"
                        placeholder=""
                        onChange={formik.handleChange}
                        value="0"
                        checked={formik.values.enabledBanner == false}
                      />
                      <label class="MuiFormLabel-root">不啟用</label>
                      <br />
                      <InputLabel id="lbUnitedBanner">播廣告時的Banner圖片(啟用時必填)</InputLabel>
                      <CardMedia component="img" src={unitedBanner} />
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<TABLE_ICONS.CameraAltIcon />}
                        onClick={inputFileUnitedBannerButton}>
                        上傳圖片
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<TABLE_ICONS.Delete />}
                        onClick={deleteUnitedBannerButton}>
                        刪除圖片
                      </Button>
                      <Box className={classes.section}>
                        <CardMedia component="img" src={newUnitedBanner} />
                        <input
                          accept="image/*"
                          type="file"
                          id="fileUnitedBanner"
                          ref={inputFileUnitedBanner}
                          style={{ display: 'none' }}
                          onChange={event => inputFileUnitedBannerOnChange(event)}
                        />
                      </Box>
                    </Box>
                  ) : null}
                  {['store_streaming'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbEnabled">直播啟用</InputLabel>
                      <input
                        id="enabled"
                        name="enabled"
                        type="radio"
                        placeholder=""
                        onChange={formik.handleChange}
                        value="1"
                        checked={formik.values.enabled == true}
                      />
                      <label class="MuiFormLabel-root">啟用　</label>
                      <input
                        id="enabled"
                        name="enabled"
                        type="radio"
                        placeholder=""
                        onChange={formik.handleChange}
                        value="0"
                        checked={formik.values.enabled == false}
                      />
                      <label class="MuiFormLabel-root">不啟用</label>
                      <br />
                      <InputLabel id="lbStreamUrl">直播URL(啟用時必填)</InputLabel>
                      <FormikControl
                        control="input"
                        name="streamUrl"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {['store_streaming'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbOrientation">直播螢幕方向</InputLabel>
                      <input
                        id="orientation"
                        name="orientation"
                        type="radio"
                        placeholder=""
                        onChange={formik.handleChange}
                        value="landscape"
                        checked={formik.values.orientation != 'portrait'}
                      />
                      <label class="MuiFormLabel-root">橫式(預設)　</label>
                      <input
                        id="orientation"
                        name="orientation"
                        type="radio"
                        placeholder=""
                        onChange={formik.handleChange}
                        value="portrait"
                        checked={formik.values.orientation == 'portrait'}
                      />
                      <label class="MuiFormLabel-root">直式</label>
                    </Box>
                  ) : null}
                  {['store_streaming'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbMuxToken">直播Token</InputLabel>
                      <FormikControl
                        control="input"
                        name="muxToken"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {['store_streaming'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbStartAt">直播開始時間</InputLabel>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDateTimePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy-MM-dd HH:mm"
                            name="startAt"
                            fullWidth
                            value={startYearMon}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            errors={formik.errors}
                            touched={formik.touched}
                            disabled={[1].includes(readonly)}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Box>
                  ) : null}
                  {['store_streaming'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbStopAt">直播結束時間</InputLabel>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDateTimePicker
                            disableToolbar
                            variant="inline"
                            format="yyyy-MM-dd HH:mm"
                            name="stopAt"
                            fullWidth
                            value={stopYearMon}
                            onChange={handleStopDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            errors={formik.errors}
                            touched={formik.touched}
                            disabled={[1].includes(readonly)}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Box>
                  ) : null}
                  {['store_streaming', 'move_play', 'fix_play','store_united_game'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbEnabledQrcodeLog">[顯示]播放成效記錄Qrcode</InputLabel>
                      <input
                        id="enabledQrcodeLog"
                        name="enabledQrcodeLog"
                        type="radio"
                        placeholder=""
                        onChange={formik.handleChange}
                        value="1"
                        checked={formik.values.enabledQrcodeLog == true}
                      />
                      <label class="MuiFormLabel-root">啟用　</label>
                      <input
                        id="enabledQrcodeLog"
                        name="enabledQrcodeLog"
                        type="radio"
                        placeholder=""
                        onChange={formik.handleChange}
                        value="0"
                        checked={formik.values.enabledQrcodeLog == false}
                      />
                      <label class="MuiFormLabel-root">不啟用</label>
                      <br />
                      <InputLabel id="lbStreamLogUrl">轉址到URL(啟用時必填)</InputLabel>
                      <FormikControl
                        control="input"
                        name="streamLogUrl"
                        fullWidth
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1].includes(readonly)}
                      />
                    </Box>
                  ) : null}
                  {['store_streaming'].includes(formik.values.moveType) ? (
                    <Box className={classes.section}>
                      <InputLabel id="lbBannerImage">直播上banner圖片</InputLabel>
                      <CardMedia component="img" src={bannerImage} />
                      <span style={bannerWidth == 0 ? { display: 'none' } : {}}>
                        解析度：{bannerWidth}X{bannerHeight}
                      </span>
                      <br />
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<TABLE_ICONS.CameraAltIcon />}
                        onClick={inputFileBannerImageButton}>
                        上傳圖片
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<TABLE_ICONS.Delete />}
                        onClick={deleteBannerImageButton}>
                        刪除圖片
                      </Button>
                      <Box className={classes.section}>
                        <CardMedia component="img" src={newBannerImage} />
                        <span style={newBannerWidth == 0 ? { display: 'none' } : {}}>
                          解析度：{newBannerWidth}X{newBannerHeight}
                        </span>
                        <br />
                        <input
                          accept="image/*"
                          type="file"
                          id="fileBannerImage"
                          ref={inputFileBannerImage}
                          style={{ display: 'none' }}
                          onChange={event => inputFileBannerImageOnChange(event)}
                        />
                      </Box>
                    </Box>
                  ) : null}
                </Grid>
                {/*右邊*/}
                <Grid item xs={6}>
                  <Box className={classes.section}>
                    <InputLabel id="lbBroadcastDate">請選擇日期(max:6天)*</InputLabel>
                    <ErrorMessage name="broadcastDate1" component="div" className={classes.sectionValidErrMsg} />
                    <DayPicker
                      name="broadcastDatePicker"
                      locale={zhTW}
                      numberOfMonths={[1, 2].includes(readonly) ? 0 : 1}
                      mode="multiple"
                      max={6}
                      defaultMonth={mMonth}
                      selected={days}
                      onSelect={setDays}
                    />
                    {formik.values.broadcastDate1 != '' ? (
                      <FormikControl
                        label="Day1."
                        control="input"
                        name="broadcastDate1"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                    {formik.values.broadcastDate2 != '' ? (
                      <FormikControl
                        label="Day2."
                        control="input"
                        name="broadcastDate2"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                    {formik.values.broadcastDate3 != '' ? (
                      <FormikControl
                        label="Day3."
                        control="input"
                        name="broadcastDate3"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                    {formik.values.broadcastDate4 != '' ? (
                      <FormikControl
                        label="Day4."
                        control="input"
                        name="broadcastDate4"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                    {formik.values.broadcastDate5 != '' ? (
                      <FormikControl
                        label="Day5."
                        control="input"
                        name="broadcastDate5"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                    {formik.values.broadcastDate6 != '' ? (
                      <FormikControl
                        label="Day6."
                        control="input"
                        name="broadcastDate6"
                        fullWidth
                        readOnly
                        errors={formik.errors}
                        touched={formik.touched}
                        disabled={[1, 2].includes(readonly)}
                      />
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box m={2}>
                    <Button variant="outlined" color="primary" type="submit" disabled={[1].includes(readonly)}>
                      {insStatus ? '確定新增' : '確定更新'}
                    </Button>
                    <Button variant="outlined" color="primary" onClick={history.goBack}>
                      取消
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'[廣告更新]'}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormikContainer;
