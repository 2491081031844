import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import { Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    '& > *': {
      margin: theme.spacing(4),
    },
  },
}));

// table 欄位標題設定
const columns = {
  parentsColoumns: [
    { title: 'No.', render: rowData => rowData.tableData.id + 1 },
    {
      title: '店家',
      render: rowData => `${rowData.store.storeName} ${rowData.store.storeCode}`,
    },
    { title: '名稱', field: 'name' },
    { title: '類型', field: 'moveType.name' },
    { title: '日期', field: 'broadcastDate' },
    { title: '車種', field: 'vehicle.type.name' },
    { title: '路線', field: 'vehicle.route.name' },
    { title: '模式', field: 'mode.name' },
    { title: '狀態', field: 'payment.statusName' },
    /*{ title: '播放清單', field: 'playlistOne.name' },*/
    /*{ title: '更新時間', field: 'updatedAt', type: 'date' },*/
  ],
};

export default function DataTable({ ad, getAdCheck }) {
  //const dispatch = useDispatch();
  //const stores = useSelector(state => state.storeReducer);
  //const [data, setData] = useState(ad.adBasic);
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState();
  const handleClickOpen = rowData => {
    setRowData(rowData);
    setOpen(true); //開啟dialog
  };
  const handleClose = () => {
    setOpen(false); //關閉dialog
  };
  //useEffect(() => {}, [dispatch]);
  // 刪除Ad API
  /*const removeAd = async rowData => {
    //console.log('刪除廣告', rowData);
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.Ad.delete(storeId, rowData.id).then(async res => {
      await ApiService.log.addEasy(storeCode, `/ad/delete/${storeCode}`, token, res.status);
    });
    getAdCheck();
  };*/

  // 編輯按鈕
  const actions = [
    /*沒新增
    {
      icon: () => (
        <Button variant="outlined" color="primary">
          新增廣告
        </Button>
      ),
      isFreeAction: true,
      tooltip: '新增廣告',
      onClick: (event, rowData) => {
        history.push(`/ad/edit?id=`);
      },
    },*/
    rowData => ({
      icon: () => <TABLE_ICONS.AssignmentTurnedInIcon />,
      tooltip: '查核內容',
      onClick: () => {
        history.push(`/adcheck/edit?id=${rowData.id}&storeId=${rowData.store.id}&readonly=1`);
      },
    }),
    /*rowData => ({
      disabled: ['request'].includes(rowData.payment.statusCode), //申請中
      icon: () => <TABLE_ICONS.ShoppingCartIcon />,
      tooltip: '送件與支付',
      onClick: (event, rowData) => {
        handleClickOpen(rowData);
      },
    }),*/
  ];

  return (
    <div>
      <MaterialTable
        title="訂閱送件查核"
        icons={TABLE_ICONS}
        columns={columns.parentsColoumns}
        data={ad}
        options={{
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            editRow: {
              deleteText: <IntlMessages id="ad.table.delete.text" />,
            },
          },
        }}
        actions={actions}
        /*editable={{
          isDeletable: rowData => ['','unpay'].includes(rowData.payment.statusCode),
          onRowDelete: rowData => removeAd(rowData),
        }}*/
      />
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'[廣告送件與支付] - '}
          {rowData ? rowData.name : ''}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description" />
          <div className={classes.button} align="center">
            <Button
              variant="outlined"
              size="large"
              onClick={handleClose}
              autoFocus
              href="#outlined-buttons"
              disabled={rowData && ['', 'request'].includes(rowData.payment.statusCode)}>
              廣告資料送件
            </Button>
            <Button
              variant="outlined"
              size="large"
              onClick={handleClose}
              href="#outlined-buttons"
              disabled={rowData && ['paid', 'request', 'accept'].includes(rowData.payment.statusCode)}>
              廣告訂閱支付
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
