import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import IntlMessages from '@jumbo/utils/IntlMessages';
import qs from 'qs';
import http from '../../../../http-common';
import { GoogleMap, KmlLayer, withGoogleMap, withScriptjs } from 'react-google-maps';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.adrecord.list'} />, link: '/adrecord/list' },
  { label: <IntlMessages id={'pages.adrecord.route'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export default function Table() {
  const classes = useStyles();
  const [adRecordDetail, setAdRecordDetail] = React.useState();
  const [center, setCenter] = React.useState();
  const storeId = localStorageService.getStoreId();
  const storeCode = localStorageService.getStoreCode();
  const token = localStorageService.getToken();

  const qstrobj = qs.parse(useLocation().search, { ignoreQueryPrefix: true });
  const recordId = qstrobj.recordId;
  const adId = qstrobj.adId;

  React.useEffect(() => {
    getAdRecordDetail();
  }, []);

  const getAdRecordDetail = async () => {
    if (storeId && adId) {
      await ApiService.AdRecord.detail(storeId, adId, recordId)
        .then(async response => {
          await ApiService.log.addEasy(storeCode, `/ad/record/detail/${storeCode}`, token, response.status);
          const records = response.data.adBroadcastRecordDetailList;
          setAdRecordDetail(records);
          var center = { lat: 25.04693832778084, lng: 121.51743252137143 }; //預設:台北車站
          if (records && records.length > 0) {
            if (records[records.length - 1].currentLongitude != '0' && records[records.length - 1].currentLatitude != '0') {
              //設定起點為中心
              center = {
                lat: parseFloat(records[records.length - 1].currentLatitude),
                lng: parseFloat(records[records.length - 1].currentLongitude),
              };
            }
          }
          setCenter(center);
        })
        .catch(err => console.log('err', err));
    }
  };

  var rand = Math.random();
  //var rurl = "https://ubobee.com/test.kml?" + rand;
  var rurl = http.defaults.baseURL + '/ad/' + adId + '/record/' + recordId + '/kml?' + rand;
  //console.log('KmlLayer_url', rurl);

  const KmlLayerExampleGoogleMap = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap defaultZoom={14} defaultCenter={center}>
        {/* lat: 41.876, lng: -87.624   lat: 25.0468026, lng: 121.2922112
        <KmlLayer url="https://googlearchive.github.io/js-v2-samples/ggeoxml/cta.kml" options={{ preserveViewport: true }} />
        */}
        <KmlLayer url={rurl} options={{ preserveViewport: true }} />
      </GoogleMap>
    )),
  );

  return (
    <PageContainer heading={<IntlMessages id="pages.adrecord.route" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section}>
        <KmlLayerExampleGoogleMap
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC-8SButSVhDGMJHNoQzgNcitOKrGz_pSc&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<Box height={1} />}
          containerElement={<Box height={{ xs: 300, sm: 400 }} />}
          mapElement={<Box height={1} />}
        />
        <DataTable adRecordDetail={adRecordDetail} getAdRecordDetail={getAdRecordDetail} recordId={recordId} />
      </Box>
    </PageContainer>
  );
}
