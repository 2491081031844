import React from 'react';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { TABLE_ICONS } from '@jumbo/constants/TableIcons';
import { CardMedia, Button } from '@material-ui/core';
import IntlMessages from '@jumbo/utils/IntlMessages';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';

// table 欄位標題設定
const columns = {
  parentsColoumns: [
    { title: 'No.', render: rowData => rowData.tableData.id + 1, width: '5%' },
    { title: '名稱', field: 'adName', width: '25%' },
    { title: '類型', field: 'adMoveType', width: '20%' },
    { title: '日期', field: 'broadcastDate', width: '20%' },
    {
      title: '記錄開始',
      render: rowData => (rowData.startDateTime ? moment(rowData.startDateTime).format('HH:mm:ss') : ''),
      width: '15%',
    },
    {
      title: '記錄結束',
      render: rowData => (rowData.endDateTime ? moment(rowData.endDateTime).format('HH:mm:ss') : ''),
      width: '15%',
    },
    /*{ title: '狀態', field: 'broadcastStatus' },*/
  ],
};

export default function DataTable({ adRecord, getAdRecord }) {
  //const dispatch = useDispatch();
  //const stores = useSelector(state => state.storeReducer);
  //const [data, setData] = useState(ad.adBasic);
  const history = useHistory();
  //useEffect(() => {}, [dispatch]);
  // 刪除Ad API
  /*const removeAd = async rowData => {
    //console.log('刪除廣告', rowData);
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.Ad.delete(storeId, rowData.id).then(async res => {
      await ApiService.log.addEasy(storeCode, `/ad/delete/${storeCode}`, token, res.status);
    });
    getAd();
  };*/
  const [open, setOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState();
  const handleClickOpen = rowData => {
    console.log('rowData', rowData);
    if (rowData) setRowData(rowData);
    setOpen(true); //開啟dialog
  };
  const handleClose = () => {
    setOpen(false); //關閉dialog
    //history.push(`/device/list`);
  };
  // 編輯按鈕
  const actions = [
    /*{
      icon: () => (
        <Button variant="outlined" color="primary">
          新增廣告
        </Button>
      ),
      isFreeAction: true,
      tooltip: '新增廣告',
      onClick: (event, rowData) => {
        history.push(`/ad/edit?id=`);
      },
    },*/
    /*rowData => ({
      icon: () => (rowData.payment.statusCode === 'paid') ? <TABLE_ICONS.VisibilityIcon /> : <TABLE_ICONS.Edit />,
      tooltip: (rowData.payment.statusCode === 'paid') ? '檢視廣告' : '編輯廣告',
      onClick: () => {
        let readonly = rowData.payment.statusCode === 'paid';
        history.push(`/ad/edit?id=${rowData.id}&readonly=${readonly}`);
      },
    }),*/
    rowData => ({
      disabled: rowData.startDateTime === null, //沒有開始時間
      icon: () => <TABLE_ICONS.MapIcon />,
      tooltip: '執行記錄',
      onClick: (event, rowData) => {
        history.push(`/adrecord/route?adId=${rowData.adId}&recordId=${rowData.id}`);
      },
    }),
    rowData => ({
      disabled: rowData.startDateTime === null, //沒有開始時間
      icon: () => <TABLE_ICONS.CameraAltIcon />,
      tooltip: '截圖',
      onClick: (event, rowData) => {
        handleClickOpen(rowData);
      },
    }),
  ];

  return (
    <div>
      <MaterialTable
        title="執行記錄管理"
        icons={TABLE_ICONS}
        columns={columns.parentsColoumns}
        data={adRecord}
        options={{
          actionsColumnIndex: -1,
        }}
        /*localization={{
          body: {
            editRow: {
              deleteText: <IntlMessages id="ad.table.delete.text" />,
            },
          },
        }}*/
        actions={actions}
        /*editable={{
          isDeletable: (rowData) => rowData.payment.statusCode !== 'paid',
          onRowDelete: rowData => removeAd(rowData),
        }}*/
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'[播放截圖]'}</DialogTitle>
        <DialogContent dividers>
          <CardMedia component="img" src={rowData ? rowData.imagePath : ''} />
          <DialogContentText id="alert-dialog-description">
            {rowData && rowData.imageUpdatedAt ? moment(rowData.imageUpdatedAt).format('YYYY-MM-DD HH:mm:ss') : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
