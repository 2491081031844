import React from 'react';
import { Field } from 'formik';
import TextField from '@material-ui/core/TextField';

function Textarea(props) {
  const { label, name, errors, touched, disabledControl, ...rest } = props;
  return (
    <div>
      <Field
        as={TextField}
        label={label}
        id={name}
        disabled={disabledControl}
        name={name}
        error={touched[name] && Boolean(errors[name])}
        helpertext={touched[name] && errors[name]}
        {...rest}
      />
    </div>
  );
}

export default Textarea;
