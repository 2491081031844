import React from 'react';
import { Field } from 'formik';
import { Select as SelectField, MenuItem } from '@material-ui/core';
function Select(props) {
  const { label, name, options, errors, touched, disabledControl, ...rest } = props;
  return (
    <div>
      <Field
        as={SelectField}
        label={label}
        name={name}
        fullWidth
        disabled={disabledControl}
        error={touched[name] && Boolean(errors[name])}
        helpertext={touched[name] && errors[name]}
        {...rest}>
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.key}
          </MenuItem>
        ))}
      </Field>
    </div>
  );
}

export default Select;
