import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import DataTable from './DataTable';
import IntlMessages from '@jumbo/utils/IntlMessages';

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.device.list'} />, isActive: true },
];

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export default function Table() {
  const classes = useStyles();
  const [deviceList, setDeviceList] = React.useState();
  React.useEffect(() => {
    getDeviceList();
  }, []);
  const getDeviceList = async () => {
    const storeId = localStorageService.getStoreId();
    const storeCode = localStorageService.getStoreCode();
    const token = localStorageService.getToken();
    await ApiService.Device.query()
      .then(async response => {
        await ApiService.log.addEasy(storeCode, `/device/query`, token, response.status);
        setDeviceList(response.data.deviceList);
      })
      .catch(err => console.log('err', err));
  };

  return (
    <PageContainer heading={<IntlMessages id="pages.device.list" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section}>
        <DataTable deviceList={deviceList} getDeviceList={getDeviceList} />
      </Box>
    </PageContainer>
  );
}
