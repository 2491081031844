import React, { forwardRef } from 'react';
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EditFormDialog from './EditFormDialog';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function StoreAccountDataTable({ storeData, getStoreData, initialValues }) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [rowData, setRowData] = React.useState({});
  const classes = useStyles();
  const [state, setState] = React.useState({
    columns: [
      { title: '店家代碼', field: 'storeCode' },
      { title: '店家名稱', field: 'storeName' },
      { title: '營業電話', field: 'storePhone' },
      { title: '營業地址', field: 'storeAddr' },
      { title: '店家介紹', field: 'storeIntro' },
      { title: '服務項目', field: 'storeService' },
      { title: '啟用狀態', field: 'storeEnabled', type: 'boolean' },
    ],
    data: [],
  });

  const openPopup = rowData => {
    setRowData({ ...rowData });
    setIsDialogOpen(true);
  };

  const actions = [
    {
      icon: () => <Edit />,
      tooltip: 'Edit the data',
      onClick: (event, rowData) => {
        // console.log('on click event | rowData', rowData)
        openPopup(rowData);
      },
    },
  ];

  return (
    <div>
      <MaterialTable
        className={classes.root}
        icons={tableIcons}
        title={<IntlMessages id="sidebar.store.info" />}
        columns={state.columns}
        data={storeData}
        options={{
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            editRow: {
              deleteText: <IntlMessages id="material.table.delete.text" />,
            },
          },
        }}
        actions={actions}
      />
      <EditFormDialog
        open={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        rowData={rowData}
        setRowData={setRowData}
        getStoreData={getStoreData}
        initialValues={initialValues}
      />
    </div>
  );
}
