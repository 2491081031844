import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import DataTable from './DataTable';
import FormDialog from './FormDialog';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  { label: <IntlMessages id={'pages.title'} />, link: '/' },
  { label: <IntlMessages id={'pages.store.list'} />, isActive: true },
];

export default function Table() {
  const classes = useStyles();
  return (
    <PageContainer heading={<IntlMessages id="pages.store.list" />} breadcrumbs={breadcrumbs}>
      <Box className={classes.section}>
        <DataTable />
      </Box>
      {/*<Box>
        <div className="row align-items-stretch mb-2 mt-3">
          <div className="col-md-12">
            <a className="btn btn-primary mr-2" href="/store/create" role="button">
              {<IntlMessages id="goto.store.create" />}
            </a>
          </div>
        </div>
      </Box>*/}
    </PageContainer>
  );
}
